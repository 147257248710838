<ion-header class="mobile" [class.with-background]="coloredToolbar" no-border>
    <ion-toolbar [style.background]="toolbarOpacity">
        <ion-buttons slot="start">
            <ion-back-button defaultHref="/" text=""></ion-back-button>
        </ion-buttons>
        <ion-title (click)="goBack()"></ion-title>
        <favorite-news-article [isFavorite]="article.is_favorite" [articleId]="article.id"></favorite-news-article>
    </ion-toolbar>
</ion-header>

<ion-header class="ion-padding-horizontal desktop" no-border>
    <ion-toolbar class="ion-no-padding">
        <profile-image></profile-image>
        <ion-buttons slot="start">
            <ion-back-button defaultHref="/" text=""></ion-back-button>
        </ion-buttons>
        <ion-title (click)="goBack()"></ion-title>
    </ion-toolbar>
</ion-header>

<ion-content (ionScroll)="onScroll($event)" *ngIf="isDataLoaded" [scrollEvents]="true">
    <div class="wrapper-div news-wrapper">
        <div class="header-container">
            <favorite-news-article [isFavorite]="article.is_favorite" [articleId]="article.id" class="favorite-article-desktop"></favorite-news-article>
            <img *ngIf="article.has_feature_image" [src]="loadSecureImage(article)" class="header" />
            <div class="overlay"></div>
        </div>

        <div>
            <div class="content-wrap">
                <span class="read-time">{{ article.reading_time }} {{ 'news.minutes-read' | translate }}</span>
                <span class="publish-time">{{ getPublishDate }}</span>
            </div>

            <div>
                <div class="content-wrap">
                    <h1>{{ article.translations[0].content }}</h1>
                </div>
                <ab-component *ngFor="let component of articleComponents" [component]="component"></ab-component>

                <div class="content-wrap">
                    <div *ngFor="let pdfAttachment of pdfComponents; let i = index"
                         [routerLink]="'/preview/3/' + pdfAttachment.translations[0].id"
                         class="news-pdf-attachment">
                        <svg-icon src="/assets/icon/mdi_picture_as_pdf.svg"></svg-icon>
                        <span class="text">
                            Bekijk bijlage {{ pdfAttachment.translations[0].content.replace(pdfAttachment.translations[0].id + '_', '').replace('.pdf', '') || i + 1 }}
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="author" class="content-wrap">
            <div (click)="authorDetails(article.profile)" class="linked-profile">
                <h2>{{ article.profile_title }}</h2>
                <p>{{ article.profile_description }}</p>
                <div class="profile-card">
                    <img [src]="fetchImageFromAuthState()" alt="{{ article.profile.name }}">
                    <div class="column text">
                        <span class="name">{{ article.profile.title }}</span>
                        <span class="label">{{ 'news-detail.view-profile' | translate }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="! author" style="margin-bottom: 100px"></div>

        <div *ngIf="articles.length" class="related-articles">
            <div class="content-wrap">
                <h3>{{ 'news-detail.related-articles' | translate }}<span class="green">.</span></h3>
            </div>

            <div class="article-list article-list--vertical">
                <div (click)="newsDetail(relatedArticle)" *ngFor="let relatedArticle of articles"
                     class="article article--vertical">
                    <img [src]="loadArticleSecureImage(relatedArticle)" alt="{{relatedArticle.title}}" />
                    <span class="readtime">{{relatedArticle.reading_time}} min lezen</span>
                    <h4>{{relatedArticle.translations[0].content}}</h4>
                </div>
            </div>
        </div>
    </div>
</ion-content>
