<ion-header class="ion-padding-horizontal" no-border>
    <ion-toolbar class="ion-no-padding">
        <profile-image></profile-image>
        <ion-title>{{ 'manual-list.title' | translate }}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <div class="wrapper-div">
        <h1 class="desktop">{{ 'manual-list.title' | translate }}</h1>
        <ion-list class="manuals-wrap">
            <ion-item
                    *ngFor="let manual of manuals"
                    [routerLink]="['/manuals', manual.id]"
                    lines="full"
            >
                <ion-label>
                    {{ manual.name }}
                </ion-label>
                <span *ngIf="hasNewVersion(manual)" class="new-manual"
                      slot="end">{{ 'manual-list.new' | translate }}</span>
            </ion-item>
        </ion-list>
    </div>
</ion-content>
